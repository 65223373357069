import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-access-denied-modal',
  templateUrl: './access-denied-modal.component.html',
  styleUrls: ['./access-denied-modal.component.scss'],
})
export class AccessDeniedModalComponent {

  constructor(private modalCtrl: ModalController) { }

  backtoTeams() {
    this.modalCtrl.dismiss();
  }

}
