<ion-content>
  <ion-card id="container">
    <ion-card-header>
      <ion-card-title>Welcome to</ion-card-title>
      <ion-img src="./../../assets/logo.png"></ion-img>
      <ion-card-subtitle>Please Log in using your credentials</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <form [formGroup]="ionicForm">
        <ion-item>
          <!-- <ion-label position="floating">Email</ion-label> -->
          <ion-input label="Email" labelPlacement="floating" formControlName="username" placeholder="Enter text"></ion-input>
        </ion-item>
        <ion-item>
          <!-- <ion-label position="floating">Password</ion-label> -->
          <ion-input label="Password" labelPlacement="floating" formControlName="password" type="password" placeholder="Enter text"></ion-input>
        </ion-item>
        <ion-button type="submit" expand="block" (click)="performLogin()">Log in</ion-button>
      </form>
    </ion-card-content>
  </ion-card>
  <div *ngIf="this.authService.authState == this.authService.authStates.Initializing" class="spinner-overlay">
    <ion-spinner class="spinner" name="crescent" color="primary"></ion-spinner>
  </div>
</ion-content>