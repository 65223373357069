<ion-header>
  <ion-toolbar class="access-denied-header">
    <ion-title>Access denied</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding access-denied-ion">
  <div class="access-denied-wrapper">
    <ion-img src="./../../assets/accessdenied.gif"></ion-img>
    <h1 class="access-denied-heading">Access denied</h1>
    <div class="access-denied-content">
      <h4>You are not allowed to perform this action</h4>
      <div (click)="backtoTeams()" class="access-denied-button">
        X Close</div>
    </div>
  </div>
</ion-content>