import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationState } from 'src/app/app.component';
import { ClubUnit_UserDto, ClubUnitLoginRequest, eJudgeClient } from '../client/client';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authStates = AuthenticationState;
  public authState = AuthenticationState.LoggedOut;
  public user: ClubUnit_UserDto;
  constructor(private client: eJudgeClient, private router: Router) {}

  public tryLogin(username: string, password: string): Observable<ClubUnit_UserDto> {
    this.authState = this.authStates.Initializing;
    return this.client.clubUnit_Login(
      new ClubUnitLoginRequest({
        email: username,
        password: password,
      })
    );
  }
  public initializeUser() {
    this.authState = this.authStates.Initializing;
    this.client.clubUnit_GetUser().subscribe(
      (user) => {
        if (this.router.url == '/') {
          this.router.navigate(['user-ui']);
        }
        this.user = user;
        this.authState = this.authStates.LoggedIn;
      },
      (error) => {
        this.authState = this.authStates.LoggedOut;
        if (this.router.url != '/') {
          this.router.navigate(['']);
        }
      }
    );
  }
  public getUser() {
    return this.user;
  }
}
