import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from './http-interceptor';
import { AppConfig } from '../models/AppConfig';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(AppConfig.backendUrl)) {
            req = req.clone({
                withCredentials: true
            });
        }

        return next.handle(req);
    }

}
