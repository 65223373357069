import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';

export enum AuthenticationState {
  Initializing,
  Unauthorized,
  LoggedOut,
  LoggedIn,
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public authService: AuthService;
  constructor(_authService: AuthService) {
    this.authService = _authService;
  }

  public ngOnInit() {
    this.authService.initializeUser();
  }
}
